<template>
  <BaseViewWrapper>
    <div class="w-full flex-col sm:flex-row sm:inline-flex sm:items-center text-center">
      <span
        class="flex flex-wrap items-center justify-center sm:mr-2 p-2 xxl:p-1 rounded-md shadow-md flex-shrink-0 relative"
        :class="[loading ? 'bg-blue-lightest pulse' : 'bg-white']"
      >
        <p class="text-blue-medium italic text-sm mr-2">
          Showing: 
        </p>
        <DateInput 
          :starting-year="startingYear"
          :number-of-years="numberOfYears"
          :disabled="loading"
          v-model="dateInputValue"
        />

        <span class="absolute -bottom-7 left-1/2 -translate-x-1/2 transform sm:translate-x-0 sm:translate-y-1/2 sm:bottom-1/2 sm:-right-24 sm:left-auto xl:-right-28">
          <transition name="fade">
            <BaseButton
              v-if="showBackToToday"
              theme="success"
              size="xs"
              :disabled="loading"
              @click="goToToday"
            >
              Back to today
            </BaseButton>
          </transition>
        </span>
      </span>

      <div class="xxl:hidden w-full text-sm text-blue-medium flex justify-center sm:justify-end items-center italic mt-9 sm:mt-0">
        <p class="mr-2">
          Show previous night
        </p>
        
        <BaseToggle
          size="sm"
          :value="!showingPrev"
          @toggle="toggleShowingPrev"
        />
      </div>
    </div>

    <div class="w-full flex gap-x-2 flex-wrap xxl:flex-no-wrap justify-center mt-6 xxl:mt-2">
      <section 
        class="w-full xxl:mb-0 xxl:w-1/5 flex-shrink-0 transition-all ease-in-out duration-500"
        :class="toggleClasses"
      >
        <RotaBoardDay 
          :loading="loading"
          :board="prevBoard"
          :show-slots="[4,5]"
          :working="tagging || notifying"
          :is-today="prevIsToday"
          :is-yesterday="selectedIsToday"
          @tag="onTagEntry"
          @notify-absent="onNotifyEntryAbsence"
        />
      </section>

      <div class="flex justify-center items-center mr-6 xxl:mr-0 xxl:ml-2 w-7 order-2 my-6 xxl:my-0 xxl:order-1 xxl:h-90vh">
        <button 
          :disabled="!showPrevious"
          class="w-7 pl-1 active:outline-none focus:outline-none relative z-10 transition-all duration-200"
          :class="{
            'cursor-not-allowed text-gray-300': !showPrevious,
            'text-blue-medium hover:text-blue-dark hover:pr-1 hover:pl-0': showPrevious && !loading,
            'text-white pulse': loading,
          }"
          @click="showPrevious ? navigatePreviousDay() : null"
        >
          <BoardLeftArrow />
        </button>
      </div>

      <section class="w-full order-1 xxl:order-2">
        <RotaBoardDay 
          :loading="loading"
          :board="selectedBoard"
          :show-slots="[1,2,3,4,5]"
          :working="tagging || notifying"
          :is-today="selectedIsToday"
          :is-yesterday="nextIsToday"
          @tag="onTagEntry"
          @notify-absent="onNotifyEntryAbsence"
        />
      </section>

      <div class="flex justify-center items-center order-3 ml-6 xxl:ml-0 xxl:h-90vh">
        <button 
          class="w-7 pr-1 transition-all duration-200 active:outline-none focus:outline-none relative z-10"
          :class="[
            loading ? 'text-white pulse' : ' text-blue-medium hover:text-blue-dark hover:pl-1 hover:pr-0'
          ]"
          @click="navigateNextDay"
        >
          <BoardRightArrow />
        </button>
      </div>
    </div>

    <BaseModal
      extend-wrapper-classes="bg-white shadow-xl"
      size="sm"
      :show="showTagModal"
      @hide="clearTagEntryState"
    >
      <ShiftTagForm
        :entry="taggingEntry"
        :tagging="tagging"
        @tagging="updateTaggingState"
        @success="onTagEntrySuccess"
        @cancel="clearTagEntryState"
      />
    </BaseModal>

    <BaseModal
      extend-wrapper-classes="bg-white shadow-xl"
      size="md"
      :show="showNotifyModal"
      @hide="clearNotifyEntryAbsenceState"
    >
      <NotifyAbsentForm
        :entry="absentEntry"
        :notifying="notifying"
        @notifying="updateNotifyingState"
        @success="onNotifyAdminSuccess"
        @cancel="clearNotifyEntryAbsenceState"
      />
    </BaseModal>

    <BaseWorking :working="tagging || notifying" />
  </BaseViewWrapper>
</template>

<script lang="ts">
import { computed, defineComponent, ref, nextTick, watch } from 'vue'
import moment from 'moment'

// Types
import { ShiftEntry } from '@/types/roster'

// Constants
import ROTA_ID from '@/constants/rota'

// Hooks
import { useRoute, useRouter } from 'vue-router'
import useRotaBoard from '@/hooks/useRotaBoard'
import useToasts from '@/hooks/useToasts'
import usePeriodStartDate from '@/hooks/usePeriodStartDate'

// Components
import RotaBoardDay from '@/components/rota/board/RotaBoardDay.vue'
import ShiftTagForm from '@/components/rota/form/ShiftTagForm.vue'
import NotifyAbsentForm from '@/components/rota/form/NotifyAbsentForm.vue'
import BoardLeftArrow from '@/components/icons/rota/BoardLeftArrow.vue'
import BoardRightArrow from '@/components/icons/rota/BoardRightArrow.vue'

export default defineComponent({
  components: {
    RotaBoardDay,
    ShiftTagForm,
    NotifyAbsentForm,
    BoardLeftArrow,
    BoardRightArrow,
  },

  setup () {
    const route = useRoute()
    const router = useRouter()
    const { warningToast, successToast } = useToasts()

    /**
     * Fetch Board
     */
    const rotaId = ref(ROTA_ID)

    const startDateString = computed(() => {
      if (
        route.query?.date && 
        moment(route.query.date.toString()).isValid() &&
        moment(route.query.date.toString()).isSameOrAfter(moment())
      ) {
        return route.query.date.toString()
      }
      return moment().format('YYYY-MM-DD')
    })

    const {
      prevBoard,
      selectedBoard,
      nextBoard,
      updateBoardEntry,
      loading,
      fetchBoard,
    } = useRotaBoard(startDateString, rotaId)

    const selectedIsToday = computed(() => {
      if (!selectedBoard.value) return false
      return selectedBoard.value.date ===  moment().format('YYYY-MM-DD')
    })

    const nextIsToday = computed(() => {
      if (!nextBoard.value) return false
      return nextBoard.value.date ===  moment().format('YYYY-MM-DD')
    })

    const prevIsToday = computed(() => {
      if (!prevBoard.value) return false
      return prevBoard.value.date ===  moment().format('YYYY-MM-DD')
    })

    // refresh at specified interval
    setInterval(() => {
      const now = moment()
      
      const limit = moment('02:00:00', 'HH:mm:ss')

      // if viewing date before today && time is after 2am, switch to today
      if (startDateString.value < now.format('YYYY-MM-DD') && now.isAfter(limit)) {
        console.log('to today')
        router.replace({
          query: {
            date: now.format('YYYY-MM-DD'),
          },
        })
      } else {
        fetchBoard() 
      }
    }, 600000) // 10 mins

    /**
     * Changing board date
     */
    const {
      startDate: inputMin,
    } = usePeriodStartDate()

    const dateInputValue = ref(startDateString.value)

    const startingYear = computed(() => moment(inputMin.value).year() ?? moment().year())
    const numberOfYears = computed(() => moment(inputMin.value).diff(moment(), 'years') + 1)

    const updateBoardDate = (val: string) => {
      const newDate = moment(val)

      if (newDate.isBefore(inputMin.value)) {
        val = moment().format('YYYY-MM-DD')
        warningToast(`Date cannot be before ${moment(inputMin.value).format('DD MMM YYYY')}.`, 'Date')
        return
      }

      const max = moment().add(11, 'months')
      if (newDate.isAfter(max)) {
        val = moment().format('YYYY-MM-DD')
        warningToast(`'Date cannot be after ${max.format('DD MMM YYYY')}.`, 'Date')
        return
      }

      router.replace({
        query: {
          date: val,
        },
      })
    }

    const showPrevious = computed(() => moment(startDateString.value).isAfter(moment()))

    const navigatePreviousDay = () => {
      const newDate = moment(startDateString.value).subtract(1, 'day')
      if (newDate.isBefore(moment(inputMin.value))) return

      dateInputValue.value = newDate.format('YYYY-MM-DD')

      nextTick(() => {
        const baseDiv = document.getElementById('base') as HTMLDivElement
        baseDiv.scrollTo({ top: 0, behavior: 'smooth' })
      })
    }

    const navigateNextDay = () => {
      const newDate = moment(startDateString.value).add(1, 'day')
      if (newDate.isBefore(moment(inputMin.value))) return

      dateInputValue.value = newDate.format('YYYY-MM-DD')

      nextTick(() => {
        const baseDiv = document.getElementById('base') as HTMLDivElement
        baseDiv.scrollTo({ top: 0, behavior: 'smooth' })
      })
    }

    const showBackToToday = computed(() => startDateString.value !== moment().format('YYYY-MM-DD'))

    const goToToday = () => {
      dateInputValue.value = moment().format('YYYY-MM-DD')
    }

    // watch dateInput for changes, update query
    watch(dateInputValue, () => {
      updateBoardDate(dateInputValue.value)
    })

    /**
     * Taggging an entry
     */
    const tagging = ref(false)
    const showTagModal = ref(false)
    const taggingEntry = ref<ShiftEntry | null>()

    const onTagEntry = (entry: ShiftEntry) => {
      if (tagging.value) return

      showTagModal.value = true
      taggingEntry.value = entry
    }

    const clearTagEntryState = () => {
      showTagModal.value = false
      taggingEntry.value = null
    }

    const updateTaggingState = (newState: boolean) => {
      if (newState) showTagModal.value = false
      tagging.value = newState
    }

    const onTagEntrySuccess = (newEntry: ShiftEntry) => {
      taggingEntry.value = null
      updateBoardEntry(newEntry)
      successToast('Tag update successful.')
    }

    /**
     * Notify Admin of absence
     */
    const notifying = ref(false)
    const showNotifyModal = ref(false)
    const absentEntry = ref<ShiftEntry | null>()

    const onNotifyEntryAbsence = (entry: ShiftEntry) => {
      if (notifying.value) return

      showNotifyModal.value = true
      absentEntry.value = entry
    }

    const clearNotifyEntryAbsenceState = () => {
      showNotifyModal.value = false
      absentEntry.value = null
    }

     const updateNotifyingState = (newState: boolean) => {
      notifying.value = newState
    }

    const onNotifyAdminSuccess = () => {
      updateNotifyingState(false)
      clearNotifyEntryAbsenceState()
      successToast('Thank you. Admin\'s have been notified.')
    }

    /**
     * Show/Hide prev night toggle
     */
    const showingPrev = ref(false)

    const toggleShowingPrev = () => {
      showingPrev.value = !showingPrev.value
    }

    const toggleClasses = computed(() => {
      return showingPrev.value ? 'max-h-night-slot-sm md:max-h-night-slot-md mb-6' : 'opacity-0 max-h-0 overflow-y-hidden xxl:h-auto xxl:max-h-full xxl:opacity-100'
    })

    return {
      prevBoard,
      selectedBoard,
      nextBoard,
      loading,
      selectedIsToday,
      nextIsToday,
      prevIsToday,
      // Changing date
      dateInputValue,
      startingYear,
      numberOfYears,
      updateBoardDate,
      goToToday,
      showBackToToday,
      showPrevious,
      navigatePreviousDay,
      navigateNextDay,
      // Tagging
      tagging,
      showTagModal,
      taggingEntry,
      onTagEntry,
      clearTagEntryState,
      updateTaggingState,
      onTagEntrySuccess,
      // Notify Absent
      notifying,
      showNotifyModal,
      absentEntry,
      onNotifyEntryAbsence,
      clearNotifyEntryAbsenceState,
      updateNotifyingState,
      onNotifyAdminSuccess,
      // Showing prev toggle
      showingPrev,
      toggleShowingPrev,
      toggleClasses,
    }
  },
})
</script>

<template>
  <div class="w-full bg-white flex flex-col">
    <RotaBoardSlotHeading 
      :active="liveSlot"
      :loading="loading"
    >
      {{ loading ? '...' : 'Night' }}
      <template #primaries>
        {{ loading ? '' : primaries }}
        <span
          v-if="onCall.length"
          class="text-base"
        >
          <a
            v-for="cons in onCall"
            :key="cons.name"
            :href="`tel:${cons.phone}`"
            class="ml-2 flex items-center hover:text-blue-dark"
          >
            <span class="w-4 h-4 flex mr-1">
              <PhoneIcon />
            </span> {{ cons.name }}
          </a>
        </span>
      </template>
    </RotaBoardSlotHeading>
    
    <div class="px-1 mt-2">
      <div 
        class="block text-right w-full py-1 text-xl md:text-lg xxl:text-xl text-white font-bold italic px-2"
        :class="[
          { 'bg-opacity-50': !liveSlot },
          loading ? 'bg-blue-lightest pulse' : 'bg-grey',
        ]"
      >
        {{ loading ? '...' : '8pm Start' }}
      </div>

      <div
        v-if="loading"
        class="my-1 relative"
      >
        <span class="uppercase absolute top-1/2 -translate-y-1/2 left-4 -translate-x-1/2 transform -rotate-90 text-white origin-center">
          ...
        </span>
        <div
          v-for="i in Array(1)"
          :key="i"
          class="border-l-32 min-h-14 flex flex-col md:flex-row xxl:flex-col md:flex-wrap xxl:flex-no-wrap px-1 gap-y-1 border-blue-lightest pulse mb-1 gap-x-2"
        >
          <div
            v-for="s in Array(2)"
            :key="s"
            class="w-full h-9 bg-blue-lightest pulse rounded-xl md:w-5/12 xl:w-3/12 xxl:w-full"
          />
        </div>
      </div>

      <template v-else>
        <div
          v-for="(value, property) in groupedEarlyEntries"
          :key="property"
          class="relative my-1"
        >
          <span class="uppercase absolute top-1/2 -translate-y-1/2 left-4 -translate-x-1/2 transform -rotate-90 text-white origin-center">
            {{ gradeMap[property] }}
          </span>
          <div 
            class="border-l-32 min-h-14 flex flex-col md:flex-row xxl:flex-col md:flex-wrap xxl:flex-no-wrap px-1 gap-y-1 gap-x-2"
            :class="[liveSlot ? 'border-blue-medium' : 'border-gray-300']"
          >
            <RotaBoardShift
              v-for="entry in value"
              :key="entry.entry_id"
              :entry="entry"
              :is-today="isToday"
              :is-yesterday="isYesterday"
              @tag="(entry) => $emit('tag', entry)"
              @notify-absent="(entry) => $emit('notify-absent', entry)"
            />
          </div>
        </div>
      </template>

      <div 
        class="block text-right w-full py-1 text-xl md:text-lg xxl:text-xl text-white font-bold italic px-2 mt-10"
        :class="[
          { 'bg-opacity-50': !liveSlot },
          loading ? 'bg-blue-lightest pulse' : 'bg-grey',
        ]"
      >
        {{ loading ? '...' : '10pm Start' }}
      </div>

      <div
        v-if="loading"
        class="my-1 relative"
      >
        <span class="uppercase absolute top-1/2 -translate-y-1/2 left-4 -translate-x-1/2 transform -rotate-90 text-white origin-center">
          ...
        </span>
        <div
          v-for="i in Array(1)"
          :key="i"
          class="border-l-32 min-h-14 flex flex-col md:flex-row xxl:flex-col md:flex-wrap xxl:flex-no-wrap px-1 gap-y-1 border-blue-lightest pulse mb-1 gap-x-2"
        >
          <div
            v-for="s in Array(2)"
            :key="s"
            class="w-full h-9 bg-blue-lightest pulse rounded-xl md:w-5/12 xl:w-3/12 xxl:w-full"
          />
        </div>
      </div>
      
      <template v-else>
        <div
          v-for="(value, property) in groupedLateEntries"
          :key="property"
          class="relative my-1"
        >
          <span class="uppercase absolute top-1/2 -translate-y-1/2 left-4 -translate-x-1/2 transform -rotate-90 text-white origin-center">
            {{ gradeMap[property] }}
          </span>
          <div 
            class="border-l-32 min-h-14 flex flex-col md:flex-row xxl:flex-col md:flex-wrap xxl:flex-no-wrap px-1 gap-y-1 gap-x-2"
            :class="[liveSlot ? 'border-blue-medium' : 'border-gray-300']"
          >
            <RotaBoardShift
              v-for="entry in value"
              :key="entry.entry_id"
              :entry="entry"
              :is-today="isToday"
              :is-yesterday="isYesterday"
              @tag="(entryId) => $emit('tag', entryId)"
              @notify-absent="(entry) => $emit('notify-absent', entry)"
            />
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType } from 'vue'
import groupBy from 'lodash/groupBy'
import { ShiftEntry } from '@/types/roster'
import { gradeMap } from '@/constants/entries'
import moment from 'moment'

// Components
import RotaBoardSlotHeading from '@/components/rota/board/RotaBoardSlotHeading.vue'
import RotaBoardShift from '@/components/rota/board/RotaBoardShift.vue'
import PhoneIcon from '@/components/icons/PhoneIcon.vue'

export default defineComponent({
  components: {
    RotaBoardSlotHeading,
    RotaBoardShift,
    PhoneIcon,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    working: {
      type: Boolean,
      required: true,
    },
    earlyEntries: {
      type: Object as PropType<ShiftEntry[]>,
      default: null,
    },
    lateEntries: {
      type: Object as PropType<ShiftEntry[]>,
      default: null,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
    isYesterday: {
      type: Boolean,
      default: false,
    },
    onCall: {
      type: Object as PropType<{
        name: string;
        phone: string;
      }[]>,
      default: [],
    },
  },

  emits: ['tag', 'notify-absent'],

  setup (props) {
    const groupedEarlyEntries = computed(() => {
      if (props.loading || !props.earlyEntries) return null

      return groupBy(props.earlyEntries, (entry: ShiftEntry) => entry.user?.grade_id)
    })

    const groupedLateEntries = computed(() => {
      if (props.loading || !props.lateEntries) return null

      return groupBy(props.lateEntries, (entry: ShiftEntry) => entry.user?.grade_id)
    })
    
    // Night primaries are slot 5
    const primaries = computed(() => {
      if (props.loading || !props.lateEntries) return ''

      return props.lateEntries.filter(e => e.shift.is_primary).map(e => e.user?.name).join(' | ')
    })

    const combinedEntries = computed(() => {
      if (
        props.loading || 
        (!props.earlyEntries && !props.lateEntries)
      ) return []

      return [
        ...props.earlyEntries,
        ...props.lateEntries,
      ]
    }) 

    const earliestStart = computed(() => {
      if (!props.isToday && !props.isYesterday) return 

      if (!combinedEntries.value?.length) return

      let earliest = moment(combinedEntries.value[0].shift.start, 'HH:mm:ss')
      if (props.isYesterday) earliest.subtract(1, 'day')

      combinedEntries.value.forEach(entry => {
        const entryEarliest = moment(entry.shift.start, 'HH:mm:ss')
        if (props.isYesterday) entryEarliest.subtract(1, 'day')

        if (entryEarliest.isBefore(earliest)) {
          earliest = entryEarliest
        }
      })

      return earliest
    })

    const latestFinish = computed(() => {
      if (!props.isToday && !props.isYesterday) return 

      if (!combinedEntries.value?.length) return

      let latest = 
        moment(combinedEntries.value[0].shift.start, 'HH:mm:ss')
        .add(combinedEntries.value[0].shift.hours, 'hours')
      if (props.isYesterday) latest.subtract(1, 'day')

      combinedEntries.value.forEach(entry => {
        const entryLatest = 
          moment(entry.shift.start, 'HH:mm:ss')
          .add(entry.shift.hours, 'hours')
        if (props.isYesterday) entryLatest.subtract(1, 'day')

        if (entryLatest.isAfter(latest)) {
          latest = entryLatest
        }
      })

      return latest
    })

    const liveSlot = computed(() => {
      if ((!props.isToday && !props.isYesterday) || !combinedEntries.value.length) return false

      const now = moment()

      if (
        now.isSameOrAfter(earliestStart.value) &&
        now.isSameOrBefore(latestFinish.value)
      ) {
        return true
      }

      return false
    })

    return {
      groupedEarlyEntries,
      groupedLateEntries,
      primaries,
      gradeMap,
      liveSlot,
    }
  },
})
</script>

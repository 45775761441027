<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 0 75.66 260"
    enable-background="new 0 0 75.66 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <polygon
      fill="currentColor"
      points="75.66,3.049 75.66,64.437 52.148,128.472 75.66,199.974 75.66,259 27.841,127.423 "
    />
    <polygon
      fill="currentColor"
      points="39.568,35.232 39.568,68.119 15.994,128.52 39.936,194.297 39.601,227.444 2,127.71 "
    />
  </svg>
</template>
<template>
  <div class="bg-blue-light">
    <div class="bg-blue-medium flex items-center justify-center text-white text-xl">
      <h2 class="py-2">
        {{ entry.user.name }}
      </h2>
    </div>
    <header class="w-full py-1 italics text-white bg-grey text-center">
      Select an assignment
    </header>
    <div class="flex flex-wrap items-center justify-center gap-2 px-1 py-3">
      <template v-if="loading">
        <div
          v-for="i in Array(12)"
          :key="i"
          class="bg-blue-lightest pulse h-8 rounded-full w-32 border-2 border-white"
        />
      </template>

      <template v-else-if="tagOptions.length">
        <button
          v-for="opt in tagOptions"
          :key="opt.id"
          class="h-8 w-32 rounded-full text-sm text-white border-2 border-white hover:bg-opacity-75"
          :style="{
            backgroundColor: opt.hex,
          }"
          @click="submitTagEntry(opt.id)"
        >
          {{ opt.label }}
        </button>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue'
import EntriesAPI from '@/apis/rota-architect/entries'
import TagsAPI from '@/apis/rota-architect/tags'
import parseErrorMap from '@/utils/parseErrorMap'
import { ShiftEntry } from '@/types/roster'

// Constants
import ROTA_ID from '@/constants/rota'

// Hooks
import useToasts from '@/hooks/useToasts'

export default defineComponent({
  props: {
    entry: {
      type: Object as PropType<ShiftEntry>,
      required: true,
    },
    tagging: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['tagging', 'success', 'cancel'],

  setup (props, ctx) {
    const { errorToast } = useToasts()

    // fetch tags.
    const loading = ref(true)
    const tags = ref<{
      id: number | null;
      label: string;
      hex: string;
    }[]>([])

    TagsAPI.all(ROTA_ID)
      .then((res) => {
        tags.value = res.data
      })
      .catch(() => {
        errorToast(`Failed to load available tags.`)
      })
      .finally(() => {
        loading.value = false
      })

    const tagOptions = computed(() => {
      if (loading.value || !tags.value.length) return []

      return [
        ...tags.value,
        {
          id: null,
          label: '-',
          hex: '#327B9B',
        },
      ]
    })

    const submitTagEntry = (tagId: number | null) => {
      if (!props.entry || props.tagging) return

      ctx.emit('tagging', true)

      EntriesAPI.tagEntry(props.entry.entry_id, { tag_id: tagId })
        .then((res) => {
          ctx.emit('success', res.data)
        })
        .catch((err) => {
          errorToast(parseErrorMap(err.response.data))
        })
        .finally(() => {
          ctx.emit('tagging', false)
        })
    }
    

    return {
      submitTagEntry,
      loading,
      tagOptions,
    }
  },
})
</script>

<template>
  <div class="relative md:w-5/12 xl:w-3/12 xxl:w-full">
    <div
      class="w-full flex rounded-xl relative"
      :class="[liveShift ? 'opacity-100 shadow-md' : 'opacity-50']"
      :style="{
        backgroundColor: hexCode,
      }"
    >
      <!-- Name -->
      <div 
        class="flex w-2/3 h-9 items-center justify-center text-blue-medium leading-none text-center bg-white rounded-xl border z-10 px-2"
        :style="{
          borderColor: hexCode,
        }"
        @contextmenu.prevent="onRightClick"
      >
        {{ entry.user.name }}
      </div>
    
      <!-- Meta -->
      <div
        class="flex flex-col w-1/3 h-9 flex-shrink-0 rounded-r-xl border"
        :style="{
          borderColor: hexCode,
        }"
      >
        <span 
          class="text-xs text-center flex items-center justify-center text-blue-medium bg-white rounded-tr-xl border-b -ml-4 pl-4"
          :style="{
            borderColor: hexCode,
          }"
        >
          {{ shiftTimes }}
        </span>
        <button
          v-if="canTagEntry"
          class="w-full flex-1 flex flex-shrink items-center justify-center text-white text-center text-xs focus:outline-none active:outline-none"
          :class="[hasTag ? 'rounded-br-xl' : '']"
          @click="assignTagClicked"
        >
          <span class="-mt-1/2">{{ tagLabel }}</span>
        </button>
        <div
          v-else
          class="w-full flex-1 flex flex-shrink items-center justify-center text-white text-center text-xs"
          :class="[hasTag ? 'rounded-br-xl' : '']"
        >
          <span class="-mt-1/2">{{ tagLabel }}</span>
        </div>
      </div>
    </div>

    <!-- Context menu -->
    <ul
      v-if="showContextMenu && (canNotifyAbsence || canTagEntry)"
      class="absolute top-4 left-2 w-48 bg-white rounded-md shadow-lg z-20"
      @mouseleave="closeContextMenu"
    >
      <li
        v-if="canNotifyAbsence"
        class="cursor-pointer flex items-center px-4 py-2 text-sm hover:bg-gray-100 transition ease-in-out duration-150 border-t border-gray-300"
        role="menuitem"
        @click="notifyAbsenceClicked"
      >
        Notify Admin of absence
      </li>
      <li
        v-if="canTagEntry"
        class="cursor-pointer flex items-center px-4 py-2 text-sm hover:bg-gray-100 transition ease-in-out duration-150 border-t border-gray-300"
        role="menuitem"
        @click="assignTagClicked"
      >
        Assign tag
      </li>
    </ul>
  </div>
</template>

<script lang="ts">
import { ShiftEntry } from '@/types/roster'
import { computed, defineComponent, PropType, ref } from 'vue'
import moment from 'moment'
import useRole from '@/hooks/useRole'

export default defineComponent({
  props: {
    entry: {
      type: Object as PropType<ShiftEntry>,
      required: true,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
    isYesterday: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['tag', 'notify-absent'],

  setup (props, ctx) {
    const hasTag = computed(() => !!props.entry.tag)
    const hexCode = computed(() => hasTag.value ? props.entry.tag?.hex : '#327B9B')
    const tagLabel = computed(() => hasTag.value ? props.entry.tag?.label : '-')

    const shiftTimes = computed(() => {
      const start = moment(props.entry.shift.start, 'HH:mm:ss').format('HH:mm')
      const end = moment(props.entry.shift.end, 'HH:mm:ss').format('HH:mm')
      return `${start} ${end}`
    })

    const liveShift = computed(() => {
      if (!props.isToday && !props.isYesterday) return false

      const now = moment()

      const start = moment(props.entry.shift.start, 'HH:mm:ss')
      const end = moment(props.entry.shift.start, 'HH:mm:ss').add(props.entry.shift.hours, 'hours')

      if (props.isYesterday) {
        start.subtract(1, 'day')
        end.subtract(1, 'day')
      }

      return now.isSameOrAfter(start) && now.isSameOrBefore(end)
    })

    /**
     * Action permissions
     */
    const { isAdmin, isDesk } = useRole()

    const canTagEntry = computed(() => isAdmin.value|| isDesk.value)

    const canNotifyAbsence = computed(() => isAdmin.value|| isDesk.value)

    /**
     * Custom context menu
     */
    const showContextMenu = ref(false)

    const onRightClick = () => {
      showContextMenu.value = true
    }

    const closeContextMenu = () => {
      showContextMenu.value = false
    }

    /**
     * Action handlers
     */
    const assignTagClicked = () => {
      if (!canTagEntry.value) return

      showContextMenu.value = false
      ctx.emit('tag', props.entry)
    }

     const notifyAbsenceClicked = () => {
       if (!canNotifyAbsence.value) return

      showContextMenu.value = false
      ctx.emit('notify-absent', props.entry)
    }

    return {
      hasTag,
      hexCode,
      tagLabel,
      shiftTimes,
      liveShift,
      // Permissions
      canTagEntry,
      canNotifyAbsence,
      // Context menu
      onRightClick,
      showContextMenu,
      closeContextMenu,
      // Actions
      assignTagClicked,
      notifyAbsenceClicked,
    }
  },
})
</script>

<template>
  <div 
    class="pt-4 pb-2 md:p-0 xxl:pt-4 xxl:pb-2 md:flex xxl:block flex-grow-0"
    :class="[
      { 'bg-opacity-75': !loading && !active },
      loading ? 'bg-blue-lightest pulse' : 'bg-blue-medium'
    ]"
  >
    <span 
      class="flex items-center justify-end w-full py-1 text-xl text-white font-bold italic px-2 md:w-1/4 xxl:w-full md:justify-center xxl:justify-end md:ml-4 xxl:ml-0"
      :class="[
        { 'bg-opacity-75': !active },
        loading ? 'bg-white' : 'bg-grey'
      ]"
    >
      <slot />
    </span>
    <span
      class="flex flex-col items-end justify-center w-full h-16 border-b-2 md:border-r-2 md:border-b-0 xxl:border-b-2 xxl:border-r-0 px-2 text-2xl font-bold text-white md:mr-2 xxl:mr-0"
      :class="[loading ? 'border-white' : 'border-grey']"
    >
      <slot name="primaries" />
    </span>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    active: { 
      type: Boolean,
      default: false,  
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
})
</script>


import { ShiftEntry } from '@/types/roster'
import { computed, defineComponent, PropType, ref } from 'vue'
import moment from 'moment'
import useRole from '@/hooks/useRole'

export default defineComponent({
  props: {
    entry: {
      type: Object as PropType<ShiftEntry>,
      required: true,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
    isYesterday: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['tag', 'notify-absent'],

  setup (props, ctx) {
    const hasTag = computed(() => !!props.entry.tag)
    const hexCode = computed(() => hasTag.value ? props.entry.tag?.hex : '#327B9B')
    const tagLabel = computed(() => hasTag.value ? props.entry.tag?.label : '-')

    const shiftTimes = computed(() => {
      const start = moment(props.entry.shift.start, 'HH:mm:ss').format('HH:mm')
      const end = moment(props.entry.shift.end, 'HH:mm:ss').format('HH:mm')
      return `${start} ${end}`
    })

    const liveShift = computed(() => {
      if (!props.isToday && !props.isYesterday) return false

      const now = moment()

      const start = moment(props.entry.shift.start, 'HH:mm:ss')
      const end = moment(props.entry.shift.start, 'HH:mm:ss').add(props.entry.shift.hours, 'hours')

      if (props.isYesterday) {
        start.subtract(1, 'day')
        end.subtract(1, 'day')
      }

      return now.isSameOrAfter(start) && now.isSameOrBefore(end)
    })

    /**
     * Action permissions
     */
    const { isAdmin, isDesk } = useRole()

    const canTagEntry = computed(() => isAdmin.value|| isDesk.value)

    const canNotifyAbsence = computed(() => isAdmin.value|| isDesk.value)

    /**
     * Custom context menu
     */
    const showContextMenu = ref(false)

    const onRightClick = () => {
      showContextMenu.value = true
    }

    const closeContextMenu = () => {
      showContextMenu.value = false
    }

    /**
     * Action handlers
     */
    const assignTagClicked = () => {
      if (!canTagEntry.value) return

      showContextMenu.value = false
      ctx.emit('tag', props.entry)
    }

     const notifyAbsenceClicked = () => {
       if (!canNotifyAbsence.value) return

      showContextMenu.value = false
      ctx.emit('notify-absent', props.entry)
    }

    return {
      hasTag,
      hexCode,
      tagLabel,
      shiftTimes,
      liveShift,
      // Permissions
      canTagEntry,
      canNotifyAbsence,
      // Context menu
      onRightClick,
      showContextMenu,
      closeContextMenu,
      // Actions
      assignTagClicked,
      notifyAbsenceClicked,
    }
  },
})

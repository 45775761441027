import { ref, Ref, watch, computed } from 'vue'
import moment from 'moment'
import RotasAPI from '@/apis/rota-architect/rotas'
import useToasts from '@/hooks/useToasts'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { BoardDate, ShiftEntry } from '@/types/roster'

export default function useRotaBoard(
  date: Ref<string>, 
  rotaId: Ref<number>,
) {

  const dispatch = useToasts()

  // fetch master rota info.
  const dates = ref<BoardDate[]>([])
  const loading = ref(false)

  const fetchBoard = () => {
    if (loading.value) return

    loading.value = true

    RotasAPI.board(rotaId.value, date.value)
      .then((res) => {
        dates.value = res.data
      })
      .catch((err) => {
        dispatch.errorToast(parseErrorMap(err.response.data))
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch(
    [rotaId, date],
    () => {
      fetchBoard()
    },
    {
      immediate: true,
    },
  )

  // for updating board outside of hook
  const updateBoardEntry = (newEntry: ShiftEntry) => {
    if (loading.value) return

    const dateIndex = dates.value.findIndex(date => date.id === newEntry.date_id)

    const entryIndex = 
      dates.value[dateIndex].entries[`slot-${newEntry.shift.slot_id}`]
        .findIndex(entry => entry.entry_id === newEntry.entry_id)

    return dates.value[dateIndex].entries[`slot-${newEntry.shift.slot_id}`][entryIndex].tag = newEntry.tag
  }

  const selectedBoard = computed(() => {
    if (!dates.value.length) return null

    return dates.value.filter(d => d.date ===  date.value)[0] ?? null
  })

  const prevBoard = computed(() => {
    if (!dates.value.length) return null

    return dates.value.filter(d => moment(d.date).add(1, 'd').format('YYYY-MM-DD') === (date.value))[0] ?? null
  })

  const nextBoard = computed(() => {
    if (!dates.value.length) return null

    return dates.value.filter(d => moment(d.date).subtract(1, 'd').format('YYYY-MM-DD') === (date.value))[0] ?? null
  })

  return {
    loading,
    prevBoard,
    selectedBoard,
    nextBoard,
    fetchBoard,
    updateBoardEntry,
  }
}

import { computed, defineComponent, PropType, ref } from 'vue'
import EntriesAPI from '@/apis/rota-architect/entries'
import TagsAPI from '@/apis/rota-architect/tags'
import parseErrorMap from '@/utils/parseErrorMap'
import { ShiftEntry } from '@/types/roster'

// Constants
import ROTA_ID from '@/constants/rota'

// Hooks
import useToasts from '@/hooks/useToasts'

export default defineComponent({
  props: {
    entry: {
      type: Object as PropType<ShiftEntry>,
      required: true,
    },
    tagging: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['tagging', 'success', 'cancel'],

  setup (props, ctx) {
    const { errorToast } = useToasts()

    // fetch tags.
    const loading = ref(true)
    const tags = ref<{
      id: number | null;
      label: string;
      hex: string;
    }[]>([])

    TagsAPI.all(ROTA_ID)
      .then((res) => {
        tags.value = res.data
      })
      .catch(() => {
        errorToast(`Failed to load available tags.`)
      })
      .finally(() => {
        loading.value = false
      })

    const tagOptions = computed(() => {
      if (loading.value || !tags.value.length) return []

      return [
        ...tags.value,
        {
          id: null,
          label: '-',
          hex: '#327B9B',
        },
      ]
    })

    const submitTagEntry = (tagId: number | null) => {
      if (!props.entry || props.tagging) return

      ctx.emit('tagging', true)

      EntriesAPI.tagEntry(props.entry.entry_id, { tag_id: tagId })
        .then((res) => {
          ctx.emit('success', res.data)
        })
        .catch((err) => {
          errorToast(parseErrorMap(err.response.data))
        })
        .finally(() => {
          ctx.emit('tagging', false)
        })
    }
    

    return {
      submitTagEntry,
      loading,
      tagOptions,
    }
  },
})

<template>
  <div>
    <BaseCard :extend-wrapper-classes="wrapperClasses">
      <h2
        class="text-center text-xl font-bold"
        :class="[loading ? 'text-blue-light' : 'text-blue-medium']"
      >
        {{ heading }}
      </h2>
    </BaseCard>

    <div class="flex flex-wrap xxl:flex-no-wrap gap-2 mt-2 xxl:min-h-80vh">
      <RotaBoardSlot
        v-if="showEarly"
        :loading="loading"
        :working="working"
        ra-slot="Early"
        :entries="board?.entries?.['slot-1'] ?? []"
        :is-today="isToday"
        @tag="(entry) => $emit('tag', entry)"
        @notify-absent="(entry) => $emit('notify-absent', entry)"
      />
      <RotaBoardSlot
        v-if="showMid"
        :loading="loading"
        :working="working"
        ra-slot="Mid"
        :entries="board?.entries?.['slot-2'] ?? []"
        :is-today="isToday"
        @tag="(entry) => $emit('tag', entry)"
        @notify-absent="(entry) => $emit('notify-absent', entry)"
      />
      <RotaBoardSlot
        v-if="showLate"
        :loading="loading"
        :working="working"
        ra-slot="Late"
        :entries="board?.entries?.['slot-3'] ?? []"
        :is-today="isToday"
        @tag="(entry) => $emit('tag', entry)"
        @notify-absent="(entry) => $emit('notify-absent', entry)"
      />

      <RotaBoardNightSlots
        v-if="showNight"
        :loading="loading"
        :working="working"
        :early-entries="board?.entries?.['slot-4'] ?? []"
        :late-entries="board?.entries?.['slot-5'] ?? []"
        :is-today="isToday"
        :is-yesterday="isYesterday"
        :on-call="onCallConsultants"
        @tag="(entry) => $emit('tag', entry)"
        @notify-absent="(entry) => $emit('notify-absent', entry)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'
import moment from 'moment'
import { BoardDate } from '@/types/roster'

// Components
import RotaBoardSlot from '@/components/rota/board/RotaBoardSlot.vue'
import RotaBoardNightSlots from '@/components/rota/board/RotaBoardNightSlots.vue'

export default defineComponent({
  components: {
    RotaBoardSlot,
    RotaBoardNightSlots,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    board: {
      type: Object as PropType<BoardDate>,
      default: null,
    },
    showSlots: {
      type: Array as PropType<number[]>,
      required: true,
    },
    working: {
      type: Boolean,
      default: false,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
    isYesterday: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['tag', 'notify-absent'],

  setup (props) {
    const heading = computed(() => {
      if (props.loading || !props.board) return '...'

      const date = moment(props.board.date).format('DD MMM YYYY')
      return `${props.board.day} - ${date}`
    })
    
    const showEarly = computed(() => props.showSlots.includes(1))
    const showMid = computed(() => props.showSlots.includes(2))
    const showLate = computed(() => props.showSlots.includes(3))
    const showNight = computed(() => props.showSlots.includes(4))

    const wrapperClasses = computed(() => {
      if (props.loading) return 'border-r-4 border-l-4 border-blue-lightest pulse py-1'
      return 'border-r-4 border-l-4 border-blue-medium py-1'
    })

    const onCallConsultants = computed(() => {
      if (props.loading || !props.board) return []
      if (!props.board.entries['slot-3']) return []

      return props.board.entries['slot-3']
        .filter(entry => entry.shift.is_primary)
        .map(entry => ({
          name: entry.user?.name,
          phone: entry.user?.phone,
        }))
    })

    return {
      heading,
      showEarly,
      showMid,
      showLate,
      showNight,
      wrapperClasses,
      onCallConsultants,
    }
  },
})
</script>

<template>
  <header class="bg-blue-medium flex items-center justify-center text-white text-xl">
    <span class="py-3 w-full text-center">
      {{ entry.user.name }}
    </span>
  </header>

  <div class="w-full text-center bg-gray-200 p-2 md:py-6 md:px-4">
    <h3 class="text-blue-dark font-bold uppercase text-lg">
      Shift
    </h3>
    <div class="flex justify-center w-64 mx-auto">
      <EntryPill
        :content="entryLabel"
        :type="entryType"
        size="full"
      />
    </div>
  </div>

  <form
    @submit="onSubmit"
    class="p-2 md:p-4 mt-2 md:mt-4"
  >
    <h2 class="text-center text-base md:text-lg pt-2 md:w-4/5 mx-auto">
      You are in the process of notifying the Rota Architect admins that <b>{{ entry.user.name }}</b> is absent from their shift.
    </h2>

    <fieldset
      class="mb-4 md:mb-8 mt-4 bg-gray-100"
      :disabled="submitting"
    >
      <p class="text-center mt-4 text-blue-medium font-bold">
        Select an absence type:
      </p>
    
      <div
        class="px-6 pt-3"
      >
        <VSelectInput
          :options="[
            { value: 'notify-sickness', label: 'Sickness' },
            { value: 'notify-absent', label: 'Didn\'t turn up' },
          ]"
          name="type"
          theme="base"
          label="Absence type"
        />
      </div>

      <p class="text-center mt-6 text-blue-medium font-bold">
        Any additional comments?
      </p>
      <div
        class="px-6 pt-3"
      >
        <VTextareaInput
          name="message"
          label="Message"
          theme="base"
          :rows="4"
          :maxlength="500"
        />
      </div>
    </fieldset>

    <div
      v-if="formErrors.entry"
      class="text-center text-red-medium my-4"
    >
      {{ formErrors.entry }}
    </div>

    <BaseErrorMessage
      v-if="submissionError"
      extend-wrapper-classes="mb-3 md:mb-6"
    >
      {{ submissionError }}
    </BaseErrorMessage>

    <div class="flex flex-row justify-end items-center gap-3 mt-4 pt-4 border-t-2 border-gray-200">
      <BaseButton
        size="md"
        theme="base"
        @click="$emit('cancel')"
        :disabled="submitting"
      >
        Cancel
      </BaseButton>

      <!-- Submit -->
      <BaseButton
        theme="success"
        size="md"
        type="submit"
        :working="submitting"
      >
        Submit
      </BaseButton>
    </div>
  </form>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue'
import EntriesAPI from '@/apis/rota-architect/entries'
import { entryPillType, entryPillLabel } from '@/hooks/useSingleUserRotaTable'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { ShiftEntry } from '@/types/roster'

// Form
import { useForm } from 'vee-validate'
import * as yup from 'yup'

// Components
import EntryPill from '@/components/rota/EntryPill.vue'
import VTextareaInput from '@/components/rota/form/VTextareaInput.vue'

export default defineComponent({
  components: {
    EntryPill,
    VTextareaInput,
  },

  props: {
    entry: {
      type: Object as PropType<ShiftEntry>,
      required: true,
    },
    notifying: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['notifying', 'success', 'cancel'],

  setup (props, ctx) {
    const entryLabel = computed(() => entryPillLabel(props.entry, 'name'))

    const entryType = computed(() => entryPillType(props.entry))


    /**
     * Form
     */
    const notifySchema: yup.ObjectSchema<{ 
       type: string; 
       message: string;
      }> = yup.object({
      type: yup
        .string()
        .required('Please select an absence type.'),
      message: yup
        .string(),
    }).defined()

      const { handleSubmit, errors } = useForm({
      validationSchema:  notifySchema,
    })

    const submitting = ref(false)
    const submissionError = ref('')
  
    const onSubmit = handleSubmit((values) => {
      if (!props.entry || props.notifying || submitting.value) return

      submitting.value = true
      submissionError.value = ''
      ctx.emit('notifying', true)

      EntriesAPI.notifyAdmins(props.entry.entry_id, {
        type: values.type,
        message: values.message ?? '',
      })
        .then((res) => {
          ctx.emit('success', res.data)
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
          ctx.emit('notifying', false)
        })
    })

    return {
      entryLabel,
      entryType,
      formErrors: errors,
      onSubmit,
      submitting,
      submissionError,
    }
  },
})
</script>


import { computed, defineComponent } from 'vue'
import { useField } from 'vee-validate'
import { StringMap } from '@/types/base'

export default defineComponent({
  inheritAttrs: false,

  props: {
    label: {
      type: String,
      required: true,
    },
    name: { // allows browser autocompletion
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: '',
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: String,
      validator: (val: string) => ['auth', 'base'].includes(val),
      default: 'auth',
    },
  },

  setup (props) {
    const { 
      value: inputValue, 
      errorMessage,
      handleBlur,
      handleChange,
      meta,
    } = useField(
      props.name, 
      '', // no validator as using form-level-validation
      {
        initialValue: props.value,
      },
    ) 

    const wrapperClasses: StringMap = {
      auth: '',
      base: 'md:flex',
    }

    const labelClasses: StringMap = {
      auth: 'block font-bold xl:text-xl mb-1 text-white',
      base: 'w-1/3 font-bold text-right italic text-gray-900 mt-3 mr-4',
    }

    const inputClasses: StringMap = {
      auth: 'p-3 text-grey bg-white text-lg',
      base: 'p-2 text-gray-900 bg-white',
    }

    const marginMap: StringMap = {
      auth: 'mb-3',
      base: 'mb-1',
    }

     const borderColorMap: StringMap = {
      auth: 'border-blue-medium',
      base: 'border-gray-300',
    }

    const errorWrapperClasses = computed(() => errorMessage.value ? marginMap[props.theme] : 'mb-3')

    const validationClasses = computed(() => (meta.valid && !props.readonly) ? 'border-green-medium' : borderColorMap[props.theme])

    return {
      inputValue, 
      errorMessage,
      handleBlur,
      handleChange,
      meta,
      wrapperClasses: wrapperClasses[props.theme],
      labelClasses: labelClasses[props.theme],
      inputClasses: inputClasses[props.theme],
      validationClasses,
      errorWrapperClasses,
    }
  },
})

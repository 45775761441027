<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="h-full w-full"
    viewBox="0 0 75.66 260"
    enable-background="new 0 0 75.66 260"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <polygon
      fill="currentColor"
      points="2,3.049 2,64.437 25.512,128.472 2,199.974 2,259 49.819,127.423 "
    />
    <polygon
      fill="currentColor"
      points="38.092,35.232 38.092,68.119 61.666,128.52 37.724,194.297 38.06,227.444 75.66,127.71 "
    />
  </svg>
</template>

import { defineComponent, PropType, computed, ref } from 'vue'
import EntriesAPI from '@/apis/rota-architect/entries'
import { entryPillType, entryPillLabel } from '@/hooks/useSingleUserRotaTable'
import parseErrorMap from '@/utils/parseErrorMap'

// Types
import { ShiftEntry } from '@/types/roster'

// Form
import { useForm } from 'vee-validate'
import * as yup from 'yup'

// Components
import EntryPill from '@/components/rota/EntryPill.vue'
import VTextareaInput from '@/components/rota/form/VTextareaInput.vue'

export default defineComponent({
  components: {
    EntryPill,
    VTextareaInput,
  },

  props: {
    entry: {
      type: Object as PropType<ShiftEntry>,
      required: true,
    },
    notifying: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['notifying', 'success', 'cancel'],

  setup (props, ctx) {
    const entryLabel = computed(() => entryPillLabel(props.entry, 'name'))

    const entryType = computed(() => entryPillType(props.entry))


    /**
     * Form
     */
    const notifySchema: yup.ObjectSchema<{ 
       type: string; 
       message: string;
      }> = yup.object({
      type: yup
        .string()
        .required('Please select an absence type.'),
      message: yup
        .string(),
    }).defined()

      const { handleSubmit, errors } = useForm({
      validationSchema:  notifySchema,
    })

    const submitting = ref(false)
    const submissionError = ref('')
  
    const onSubmit = handleSubmit((values) => {
      if (!props.entry || props.notifying || submitting.value) return

      submitting.value = true
      submissionError.value = ''
      ctx.emit('notifying', true)

      EntriesAPI.notifyAdmins(props.entry.entry_id, {
        type: values.type,
        message: values.message ?? '',
      })
        .then((res) => {
          ctx.emit('success', res.data)
        })
        .catch((err) => {
          submissionError.value = parseErrorMap(err.response.data)
        })
        .finally(() => {
          submitting.value = false
          ctx.emit('notifying', false)
        })
    })

    return {
      entryLabel,
      entryType,
      formErrors: errors,
      onSubmit,
      submitting,
      submissionError,
    }
  },
})

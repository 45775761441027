
import { defineComponent, computed, PropType } from 'vue'
import groupBy from 'lodash/groupBy'
import { ShiftEntry } from '@/types/roster'
import { gradeMap } from '@/constants/entries'
import moment from 'moment'

// Components
import RotaBoardSlotHeading from '@/components/rota/board/RotaBoardSlotHeading.vue'
import RotaBoardShift from '@/components/rota/board/RotaBoardShift.vue'
import PhoneIcon from '@/components/icons/PhoneIcon.vue'

export default defineComponent({
  components: {
    RotaBoardSlotHeading,
    RotaBoardShift,
    PhoneIcon,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    working: {
      type: Boolean,
      required: true,
    },
    earlyEntries: {
      type: Object as PropType<ShiftEntry[]>,
      default: null,
    },
    lateEntries: {
      type: Object as PropType<ShiftEntry[]>,
      default: null,
    },
    isToday: {
      type: Boolean,
      default: false,
    },
    isYesterday: {
      type: Boolean,
      default: false,
    },
    onCall: {
      type: Object as PropType<{
        name: string;
        phone: string;
      }[]>,
      default: [],
    },
  },

  emits: ['tag', 'notify-absent'],

  setup (props) {
    const groupedEarlyEntries = computed(() => {
      if (props.loading || !props.earlyEntries) return null

      return groupBy(props.earlyEntries, (entry: ShiftEntry) => entry.user?.grade_id)
    })

    const groupedLateEntries = computed(() => {
      if (props.loading || !props.lateEntries) return null

      return groupBy(props.lateEntries, (entry: ShiftEntry) => entry.user?.grade_id)
    })
    
    // Night primaries are slot 5
    const primaries = computed(() => {
      if (props.loading || !props.lateEntries) return ''

      return props.lateEntries.filter(e => e.shift.is_primary).map(e => e.user?.name).join(' | ')
    })

    const combinedEntries = computed(() => {
      if (
        props.loading || 
        (!props.earlyEntries && !props.lateEntries)
      ) return []

      return [
        ...props.earlyEntries,
        ...props.lateEntries,
      ]
    }) 

    const earliestStart = computed(() => {
      if (!props.isToday && !props.isYesterday) return 

      if (!combinedEntries.value?.length) return

      let earliest = moment(combinedEntries.value[0].shift.start, 'HH:mm:ss')
      if (props.isYesterday) earliest.subtract(1, 'day')

      combinedEntries.value.forEach(entry => {
        const entryEarliest = moment(entry.shift.start, 'HH:mm:ss')
        if (props.isYesterday) entryEarliest.subtract(1, 'day')

        if (entryEarliest.isBefore(earliest)) {
          earliest = entryEarliest
        }
      })

      return earliest
    })

    const latestFinish = computed(() => {
      if (!props.isToday && !props.isYesterday) return 

      if (!combinedEntries.value?.length) return

      let latest = 
        moment(combinedEntries.value[0].shift.start, 'HH:mm:ss')
        .add(combinedEntries.value[0].shift.hours, 'hours')
      if (props.isYesterday) latest.subtract(1, 'day')

      combinedEntries.value.forEach(entry => {
        const entryLatest = 
          moment(entry.shift.start, 'HH:mm:ss')
          .add(entry.shift.hours, 'hours')
        if (props.isYesterday) entryLatest.subtract(1, 'day')

        if (entryLatest.isAfter(latest)) {
          latest = entryLatest
        }
      })

      return latest
    })

    const liveSlot = computed(() => {
      if ((!props.isToday && !props.isYesterday) || !combinedEntries.value.length) return false

      const now = moment()

      if (
        now.isSameOrAfter(earliestStart.value) &&
        now.isSameOrBefore(latestFinish.value)
      ) {
        return true
      }

      return false
    })

    return {
      groupedEarlyEntries,
      groupedLateEntries,
      primaries,
      gradeMap,
      liveSlot,
    }
  },
})


import { defineComponent, computed, PropType } from 'vue'
import groupBy from 'lodash/groupBy'
import { ShiftEntry } from '@/types/roster'
import { gradeMap } from '@/constants/entries'
import moment from 'moment'

// Components
import RotaBoardSlotHeading from '@/components/rota/board/RotaBoardSlotHeading.vue'
import RotaBoardShift from '@/components/rota/board/RotaBoardShift.vue'

export default defineComponent({
  components: {
    RotaBoardSlotHeading,
    RotaBoardShift,
  },

  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    working: {
      type: Boolean,
      required: true,
    },
    raSlot: {
      type: String,
      validator: (val: string) => ['Early', 'Mid', 'Late'].includes(val),
      required: true,
    },
    entries: {
      type: Array as PropType<ShiftEntry[]>,
      required: true,
    },
    isToday: {
      type: Boolean,
      required: true,
    },
  },

  emits: ['tag', 'notify-absent'],

  setup (props) {
    const groupedEntries = computed(() => {
      if (props.loading || !props.entries) return null

      const grouped = groupBy(props.entries, (entry: ShiftEntry) => entry.user?.grade_id)

      for (const group in grouped) {
        grouped[group].sort((a, b) => {
          if (a.shift.is_primary === b.shift.is_primary) return 0

          return b.shift.is_primary - a.shift.is_primary
        })
      }

      return grouped
    })
    
    const primaries = computed(() => {
      if (props.loading || !props.entries) return ''

      return props.entries.filter(e => e.shift.is_primary).map(e => e.user?.name).join(' | ')
    })

    const earliestStart = computed(() => {
      if (!props.isToday) return 

      if (props.loading || !props.entries || !props.entries.length) return ''

      let earliest = moment(props.entries[0].shift.start, 'HH:mm:ss')
      props.entries.forEach(entry => {
        if (moment(entry.shift.start, 'HH:mm:ss').isBefore(earliest)) {
          earliest = moment(entry.shift.start, 'HH:mm:ss')
        }
      })

      return earliest
    })

    const latestFinish = computed(() => {
      if (!props.isToday) return 

      if (props.loading || !props.entries || !props.entries.length) return ''

      let latest = 
        moment(props.entries[0].shift.start, 'HH:mm:ss')
          .add(props.entries[0].shift.hours, 'hours')

      props.entries.forEach(entry => {
        const end =
          moment(entry.shift.start, 'HH:mm:ss')
          .add(entry.shift.hours, 'hours')

        if (end.isAfter(latest)) {
          latest = end
        }
      })

      return latest
    })

    const liveSlot = computed(() => {
      if (!props.isToday) return false

      const now = moment()

      if (
        now.isSameOrAfter(earliestStart.value) &&
        now.isSameOrBefore(latestFinish.value)
      ) {
        return true
      }

      return false
    })

    return {
      groupedEntries,
      primaries,
      gradeMap,
      liveSlot,
    }
  },
})

<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    class="w-full h-full"
    viewBox="5.25 5.75 244.25 244.75"
    enable-background="new 5.25 5.75 244.25 244.75"
    xml:space="preserve"
  >
    <g
      id="Background"
      display="none"
    >
      <rect
        display="inline"
        fill="#333333"
        width="260"
        height="260"
      />
    </g>
    <path
      fill="currentColor"
      d="M7.25,56.5C-2,110.75,51.5,251,199.25,249.25c21.5-5.75,31.5-27.25,31.5-27.25
	c7.5-30.75-15.25-39.25-15.25-39.25s-14.5-5-33.25-9.5s-36.5,18-36.5,18c-37.25,3-82.75-57.5-80.25-80.5L78.75,97
	c0,0,7.741-11.469,4.25-23.25S76,46.5,73,40S56.25,12.25,29.25,29.75C20.926,37.425,11.5,44.25,7.25,56.5z"
    />
    <path
      fill="currentColor"
      d="M128.375,113V98.25c0,0,29.125-0.75,30.625,29.875h-15.313C143.5,114.5,128.375,113,128.375,113z"
    />
    <path
      fill="currentColor"
      d="M128.375,67.5V52.75c0,0,71.625-2.75,75.375,75.375h-14.5C187.25,69,128.375,67.5,128.375,67.5z"
    />
    <path
      fill="currentColor"
      d="M128.375,22.99V7c0,0,115.375-2.5,121.125,121.125h-15.25C228.75,24,128.375,22.99,128.375,22.99z"
    />
  </svg>
</template>
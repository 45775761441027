
import { computed, defineComponent, ref, nextTick, watch } from 'vue'
import moment from 'moment'

// Types
import { ShiftEntry } from '@/types/roster'

// Constants
import ROTA_ID from '@/constants/rota'

// Hooks
import { useRoute, useRouter } from 'vue-router'
import useRotaBoard from '@/hooks/useRotaBoard'
import useToasts from '@/hooks/useToasts'
import usePeriodStartDate from '@/hooks/usePeriodStartDate'

// Components
import RotaBoardDay from '@/components/rota/board/RotaBoardDay.vue'
import ShiftTagForm from '@/components/rota/form/ShiftTagForm.vue'
import NotifyAbsentForm from '@/components/rota/form/NotifyAbsentForm.vue'
import BoardLeftArrow from '@/components/icons/rota/BoardLeftArrow.vue'
import BoardRightArrow from '@/components/icons/rota/BoardRightArrow.vue'

export default defineComponent({
  components: {
    RotaBoardDay,
    ShiftTagForm,
    NotifyAbsentForm,
    BoardLeftArrow,
    BoardRightArrow,
  },

  setup () {
    const route = useRoute()
    const router = useRouter()
    const { warningToast, successToast } = useToasts()

    /**
     * Fetch Board
     */
    const rotaId = ref(ROTA_ID)

    const startDateString = computed(() => {
      if (
        route.query?.date && 
        moment(route.query.date.toString()).isValid() &&
        moment(route.query.date.toString()).isSameOrAfter(moment())
      ) {
        return route.query.date.toString()
      }
      return moment().format('YYYY-MM-DD')
    })

    const {
      prevBoard,
      selectedBoard,
      nextBoard,
      updateBoardEntry,
      loading,
      fetchBoard,
    } = useRotaBoard(startDateString, rotaId)

    const selectedIsToday = computed(() => {
      if (!selectedBoard.value) return false
      return selectedBoard.value.date ===  moment().format('YYYY-MM-DD')
    })

    const nextIsToday = computed(() => {
      if (!nextBoard.value) return false
      return nextBoard.value.date ===  moment().format('YYYY-MM-DD')
    })

    const prevIsToday = computed(() => {
      if (!prevBoard.value) return false
      return prevBoard.value.date ===  moment().format('YYYY-MM-DD')
    })

    // refresh at specified interval
    setInterval(() => {
      const now = moment()
      
      const limit = moment('02:00:00', 'HH:mm:ss')

      // if viewing date before today && time is after 2am, switch to today
      if (startDateString.value < now.format('YYYY-MM-DD') && now.isAfter(limit)) {
        console.log('to today')
        router.replace({
          query: {
            date: now.format('YYYY-MM-DD'),
          },
        })
      } else {
        fetchBoard() 
      }
    }, 600000) // 10 mins

    /**
     * Changing board date
     */
    const {
      startDate: inputMin,
    } = usePeriodStartDate()

    const dateInputValue = ref(startDateString.value)

    const startingYear = computed(() => moment(inputMin.value).year() ?? moment().year())
    const numberOfYears = computed(() => moment(inputMin.value).diff(moment(), 'years') + 1)

    const updateBoardDate = (val: string) => {
      const newDate = moment(val)

      if (newDate.isBefore(inputMin.value)) {
        val = moment().format('YYYY-MM-DD')
        warningToast(`Date cannot be before ${moment(inputMin.value).format('DD MMM YYYY')}.`, 'Date')
        return
      }

      const max = moment().add(11, 'months')
      if (newDate.isAfter(max)) {
        val = moment().format('YYYY-MM-DD')
        warningToast(`'Date cannot be after ${max.format('DD MMM YYYY')}.`, 'Date')
        return
      }

      router.replace({
        query: {
          date: val,
        },
      })
    }

    const showPrevious = computed(() => moment(startDateString.value).isAfter(moment()))

    const navigatePreviousDay = () => {
      const newDate = moment(startDateString.value).subtract(1, 'day')
      if (newDate.isBefore(moment(inputMin.value))) return

      dateInputValue.value = newDate.format('YYYY-MM-DD')

      nextTick(() => {
        const baseDiv = document.getElementById('base') as HTMLDivElement
        baseDiv.scrollTo({ top: 0, behavior: 'smooth' })
      })
    }

    const navigateNextDay = () => {
      const newDate = moment(startDateString.value).add(1, 'day')
      if (newDate.isBefore(moment(inputMin.value))) return

      dateInputValue.value = newDate.format('YYYY-MM-DD')

      nextTick(() => {
        const baseDiv = document.getElementById('base') as HTMLDivElement
        baseDiv.scrollTo({ top: 0, behavior: 'smooth' })
      })
    }

    const showBackToToday = computed(() => startDateString.value !== moment().format('YYYY-MM-DD'))

    const goToToday = () => {
      dateInputValue.value = moment().format('YYYY-MM-DD')
    }

    // watch dateInput for changes, update query
    watch(dateInputValue, () => {
      updateBoardDate(dateInputValue.value)
    })

    /**
     * Taggging an entry
     */
    const tagging = ref(false)
    const showTagModal = ref(false)
    const taggingEntry = ref<ShiftEntry | null>()

    const onTagEntry = (entry: ShiftEntry) => {
      if (tagging.value) return

      showTagModal.value = true
      taggingEntry.value = entry
    }

    const clearTagEntryState = () => {
      showTagModal.value = false
      taggingEntry.value = null
    }

    const updateTaggingState = (newState: boolean) => {
      if (newState) showTagModal.value = false
      tagging.value = newState
    }

    const onTagEntrySuccess = (newEntry: ShiftEntry) => {
      taggingEntry.value = null
      updateBoardEntry(newEntry)
      successToast('Tag update successful.')
    }

    /**
     * Notify Admin of absence
     */
    const notifying = ref(false)
    const showNotifyModal = ref(false)
    const absentEntry = ref<ShiftEntry | null>()

    const onNotifyEntryAbsence = (entry: ShiftEntry) => {
      if (notifying.value) return

      showNotifyModal.value = true
      absentEntry.value = entry
    }

    const clearNotifyEntryAbsenceState = () => {
      showNotifyModal.value = false
      absentEntry.value = null
    }

     const updateNotifyingState = (newState: boolean) => {
      notifying.value = newState
    }

    const onNotifyAdminSuccess = () => {
      updateNotifyingState(false)
      clearNotifyEntryAbsenceState()
      successToast('Thank you. Admin\'s have been notified.')
    }

    /**
     * Show/Hide prev night toggle
     */
    const showingPrev = ref(false)

    const toggleShowingPrev = () => {
      showingPrev.value = !showingPrev.value
    }

    const toggleClasses = computed(() => {
      return showingPrev.value ? 'max-h-night-slot-sm md:max-h-night-slot-md mb-6' : 'opacity-0 max-h-0 overflow-y-hidden xxl:h-auto xxl:max-h-full xxl:opacity-100'
    })

    return {
      prevBoard,
      selectedBoard,
      nextBoard,
      loading,
      selectedIsToday,
      nextIsToday,
      prevIsToday,
      // Changing date
      dateInputValue,
      startingYear,
      numberOfYears,
      updateBoardDate,
      goToToday,
      showBackToToday,
      showPrevious,
      navigatePreviousDay,
      navigateNextDay,
      // Tagging
      tagging,
      showTagModal,
      taggingEntry,
      onTagEntry,
      clearTagEntryState,
      updateTaggingState,
      onTagEntrySuccess,
      // Notify Absent
      notifying,
      showNotifyModal,
      absentEntry,
      onNotifyEntryAbsence,
      clearNotifyEntryAbsenceState,
      updateNotifyingState,
      onNotifyAdminSuccess,
      // Showing prev toggle
      showingPrev,
      toggleShowingPrev,
      toggleClasses,
    }
  },
})
